@import "../../../global/scss/vendor/reset-css";
@import "../../../global/scss/variables";
@import "../../../global/scss/mixins/breakpoint";
@import "../../../global/scss/mixins/browser-targeting";


// img sizes
.desktop {
  .img-1 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-2 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-3 {
    // w: 4000px, h:4500px
    padding-top: 112.50%;
  }

  .img-4 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-5 {
    // w: 3998px, h:4500px
    padding-top: 112.56%;
  }

  .img-6 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-7 {
    // w: 3998px, h:4500px
    padding-top: 112.56%;
  }

  .img-8 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }

  .img-9 {
    // w: 3998px, h:4500px
    padding-top: 112.56%;
  }

  .img-10 {
    // w: 4000px, h:2250px
    padding-top: 56.25%;
  }
}

.tablet, .mobile {
  .img-1 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-2 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-3 {
    // w: 2344px, h:8533px
    padding-top: 284.5%;
  }

  .img-4 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-5 {
    // w: 2344px, h:8533px
    padding-top: 338%;
  }

  .img-6 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-7 {
    // w: 2344px, h:8533px
    padding-top: 364.04%;
  }

  .img-8 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }

  .img-9 {
    // w: 2344px, h:8533px
    padding-top: 364.04%;
  }

  .img-10 {
    // w: 2344px, h:4169px
    padding-top: 177.86%;
  }
}

// videos
.desktop {
  .img-3 {
    .video {
      top: 45.4%;
      left: 60.5%;
      width: 33.6%;
      height: 361px;
      background: rgba(255, 255, 255, 0.7);
    }
    .video__embed {
      width: 100%;
      height: 100%;
    }
  }
}

.tablet {
  .img-3 {
    .video {
      top: 62.5%;
      left: 47.3%;
      width: 47.9%;
      height: 304px;
      background: rgba(255, 255, 255, 0.7);
      @media only screen and (max-width: 1060px) {
        height: 290px;
      }

      @media only screen and (max-width: 1020px) {
        height: 280px;
      }

      @media only screen and (max-width: 980px) {
        height: 270px;
      }

      @media only screen and (max-width: 950px) {
        height: 260px;
      }
      @media only screen and (max-width: 900px) {
        height: 245px;
      }
    }
    .video__embed {
      width: 100%;
      height: 100%;
    }
  }
}


.mobile {
  .img-3 {
    .video {
      top: 62.5%;
      left: 47.3%;
      width: 47.8%;
      height: 205px;
      background: rgba(255, 255, 255, 1);

      @media only screen and (max-width: 720px) {
        height: 195px;
      }

      @media only screen and (max-width: 620px) {
        left: 9%;
        width: 86.1%;
        height: 180px;
      }

      @media only screen and (max-width: 550px) {
        top: 62.2%;
        height: 160px;
      }

      @media only screen and (max-width: 500px) {

      }

      @media only screen and (max-width: 490px) {
        height: 140px;
      }

      @media only screen and (max-width: 400px) {
        height: 135px;
      }

      @media only screen and (max-width: 350px) {
        top: 61%;
      }


    }
    .video__embed {
      width: 100%;
      height: 100%;
    }
  }
}

@import "../../../global/scss/global";
@import "../../../global/scss/video";
@import "../../../global/scss/confirm-container";
@import "../../../global/scss/button";
@import "../../../global/scss/link";

//@import "../../../global/scss/debug";